const NodeCache = require('node-cache');

// Node Cache client configuration
const cacheConfig = {
  stdTTL: Number(process.env.MEM_CACHE_TTL) || 300, // Default TTL in seconds (5 minutes)
  checkperiod: 600, // Cleanup check interval in seconds (10 minutes)
  useClones: false, // For better performance, doesn't clone objects on get/set
  deleteOnExpire: true, // Remove items when they expire
};

// Create cache instance
let cacheInstance = null;

function getCache() {
  if (!cacheInstance) {
    cacheInstance = new NodeCache(cacheConfig);

    // Log cache statistical information periodically if in development
    if (process.env.NODE_ENV === 'development') {
      setInterval(() => {
        const metrics = cacheInstance.getStats();
        // eslint-disable-next-line no-console
        console.debug('[Cache] Stats:', metrics);
      }, 300000); // Every 5 minutes
    }
  }

  return cacheInstance;
}

/**
 * Get a value from cache
 * @param {string} key - The key to get
 * @returns {any} - The cached value or undefined if not found
 */
function get(key) {
  try {
    return getCache().get(key);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`[Cache] Error getting key ${key}:`, error);
    return undefined;
  }
}

/**
 * Set a value in cache
 * @param {string} key - The key to set
 * @param {any} value - The value to store
 * @param {number} ttl - Time to live in seconds (overrides default TTL)
 * @returns {boolean} - Success status
 */
function set(key, value, ttl = cacheConfig.stdTTL) {
  try {
    return getCache().set(key, value, ttl);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`[Cache] Error setting key ${key}:`, error);
    return false;
  }
}

/**
 * Check if a key exists in cache
 * @param {string} key - The key to check
 * @returns {boolean} - True if the key exists
 */
function has(key) {
  try {
    return getCache().has(key);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`[Cache] Error checking key ${key}:`, error);
    return false;
  }
}

/**
 * Delete a key from cache
 * @param {string} key - The key to delete
 * @returns {number} - Number of deleted entries
 */
function del(key) {
  try {
    return getCache().del(key);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`[Cache] Error deleting key ${key}:`, error);
    return 0;
  }
}

/**
 * Clear all cache entries
 * @returns {void}
 */
function clear() {
  try {
    return getCache().flushAll();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('[Cache] Error clearing cache:', error);
  }
  return null;
}

/**
 * Get cache statistics
 * @returns {object} - Cache statistics
 */
function stats() {
  try {
    return getCache().getStats();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('[Cache] Error getting stats:', error);
    return {};
  }
}

/**
 * Get all keys in cache
 * @returns {string[]} - Array of keys
 */
function keys() {
  try {
    return getCache().keys();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('[Cache] Error getting keys:', error);
    return [];
  }
}

module.exports = {
  cache: {
    instance: getCache(),
    get,
    set,
    has,
    del,
    clear,
    stats,
    keys,
  },
};
